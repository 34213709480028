import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import burgerIcon from "../../assets/images/burgerIcon.svg";
// import downArrow from "../../assets/images/down-arrow.svg";
import { useLogoutMutation } from "../../features/auth/authSlice";
import { useTranslation } from "react-i18next";

const Navbar = ({ showSidebar, toggleSidebar }) => {
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const languages = [
    { label: "English", value: "en-US" },
    { label: "Nederlands", value: "nl-NL" },
    { label: "Deutsch", value: "de-DE" },
    { label: "中文", value: "cn-CN" },
    { label: "Español", value: "es-ES" },
    { label: "Português", value: "pt-PT" },
    { label: "Français", value: "fr-FR" },
    { label: "Italiano", value: "it-IT" },
    { label: "Русский", value: "rs-RS" },
    { label: "日本語", value: "jp-JP" },
    { label: "Dansk", value: "dk-DK" },
    { label: "Română", value: "ro-RO" },
    { label: "Suomalainen", value: "fi-FI" },
  ];

  const handleLogoutClick = (e) => {
    logout().unwrap();
    window.localStorage.removeItem("access_token");
    navigate("/login", { state: { from: pathname } });
  };

  const handleEditClick = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    window.location.reload(false);
  };

  return (
    <nav className="h-[75px] sticky top-0 bg-theme-secondary grid">
      <div className={`flex justify-between items-center w-full px-5`}>
        <div className="">
          {!showSidebar && (
            <img
              onClick={toggleSidebar}
              className="w-[25px] cursor-pointer"
              src={burgerIcon}
              alt=""
            />
          )}
        </div>
        <div className="flex gap-10">
          <div className="flex items-center gap-2">
            <select
              name="lang"
              id="lang"
              className="bg-theme-secondary text-white focus-visible:outline-none"
              onChange={(e) => handleEditClick(e)}
              defaultValue={localStorage.getItem("lang")}
            >
              {languages.map((item, index) => {
                return (
                  <option
                    className="bg-white text-black"
                    key={index}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                );
              })}
            </select>

            {/* <p className="theme-text-24 font-semibold text-white">English</p> */}
            {/* <img className="w-[14px] cursor-pointer" src={downArrow} alt="" /> */}
          </div>
          <button
            onClick={handleLogoutClick}
            className="bg-theme-pink theme-text-18 font-semibold text-white py-4 px-12"
          >
            {t("common.logout", "Logout")}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
